/* DESIGN SYSTEM */

/* color tokens */
$black: hsl(0, 0%, 0%, 1);
$white: hsl(0, 0%, 100%, 1);

$grey-600: hsl(0, 0%, 15%, 1);
$grey-550: hsl(0, 0%, 35%, 1);
$grey-500: hsl(0, 0%, 30%, 1);
$grey-400: hsl(0, 0%, 45%, 1);
$grey-300: hsl(0, 2%, 88%, 1);
$grey-200: hsl(0, 2%, 92%, 1);
$grey-100: hsl(0, 5%, 96%, 1);
$grey-000: hsl(0, 0%, 99%, 1);

$blue-700: hsl(228, 51%, 27%, 1);
$blue-600: hsl(211, 100%, 31%, 1);
$blue-500: hsl(211, 100%, 43%, 1);
$blue-400: hsl(208, 100%, 47%, 1);
$blue-300: hsl(206, 100%, 50%, 1);
$blue-200: hsl(210, 100%, 94%, 1);
$blue-100: hsl(210, 100%, 96%, 1);

$green-700: hsl(137, 61%, 20%, 1);
$green-600: hsl(133, 50%, 23%, 1);
$green-500: hsl(133, 50%, 32%, 1);
$green-400: hsl(132, 43%, 42%, 1);
$green-300: hsl(131, 41%, 46%, 1);
$green-200: hsl(120, 45%, 91%, 1);
$green-100: hsl(120, 54%, 95%, 1);

$red-600: hsl(10, 82%, 32%, 1);
$red-500: hsl(10, 82%, 44%, 1);
$red-400: hsl(10, 72%, 50%, 1);
$red-300: hsl(10, 78%, 54%, 1);
$red-200: hsl(8, 100%, 94%, 1);
$red-100: hsl(7, 100%, 97%, 1);

$yellow-700: hsl(28, 60%, 95%);
$yellow-600: hsl(30, 100%, 24%, 1);
$yellow-500: hsl(30, 100%, 34%, 1);
$yellow-400: hsl(35, 100%, 55%, 1);
$yellow-300: hsl(39, 100%, 57%, 1);
$yellow-200: hsl(43, 100%, 87%, 1);
$yellow-100: hsl(44, 100%, 92%, 1);

/* vendor colors */
$facebook-blue: #3b5998;
$paypal-gold: #ffc439;

// $paypal-gold:hover { filter: brightness(0.95); }
