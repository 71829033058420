$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';

.image-carousel-pips {
  @include z-index(gallery-pips);

  bottom: 5%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);

  &--progress-bar {
    background: $grey-200 !important;
    bottom: -4px !important;
    display: flex;
    justify-content: space-evenly;
    max-height: 4px;
    right: 0 !important;
    width: 100%;
  }
}

.image-carousel-pip {
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-block;
  height: 7px;
  margin: 0 8px;
  opacity: 0.5;
  padding: 0;
  width: 7px;

  &--active {
    opacity: 1;
  }

  &--progress-bar {
    border: none !important;
    border-radius: 0;
    height: 4px;
    margin: 0 !important;
    opacity: 1;
    width: 100%;
  }

  &--progress-bar-active {
    background: $black !important;
  }

  &--progress-bar-prev-left {
    background: $black !important;
    position: relative;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  &--progress-bar-prev-right {
    background: $black !important;
    position: relative;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }
}
