$use_maison: true;
@import 'styles/colors';
@import 'styles/mixins';

.product-image-carousel {
  background-color: $grey-100;
  height: calc(100vw * 1.25); // 4:5 ratio
  position: relative;
  width: 100vw;

  @include breakpoint(desktop) {
    background-color: $white;
    height: auto;
    width: auto;
  }

  .product-carousel__pips {
    bottom: 10px;
    left: unset;
    right: 15px;
    transform: none;
  }

  .product-carousel__pips__pip {
    background-color: transparent;
    border: 1px solid $grey-400;
    margin: 0;
    margin-right: 5px;
    opacity: 1;
  }

  .product-carousel__pips__pip--active {
    background-color: $grey-400;
  }
}

.product-image-carousel__image {
  background-color: $grey-100;
  display: flex;
  height: auto;
  width: 100%;
}

.product-image-carousel__video {
  background: $black;
  display: flex;
  height: auto;
  width: calc(100vw * 1.25); // 4:5 ratio
}

.product-image-carousel__video-active {
  left: -12.5vw; // centering the video
  position: relative;
}

.carousel-bar__slider {
  background-color: $grey-300;
  bottom: 25px;
  height: 3px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  width: 85%;
}

.carousel-bar__slider-active {
  background-color: $grey-400;
  height: 100%;
  position: absolute;
}
