/* stylelint-disable font-family-no-missing-generic-family-keyword */
@import '@everlane/typography/scss';
@import '@everlane/typography/mixins/typography';
@import './zindex.scss';

$new-styles: (
  'display-large',
  'display-medium',
  'display-small',
  'heading-large',
  'heading-medium',
  'heading-medium-bold',
  'heading-small',
  'heading-small-bold',
  'heading-xsmall',
  'heading-xsmall-bold',
  'body-large',
  'body-large-bold',
  'body-medium',
  'body-medium-bold',
  'body-small',
  'body-small-bold',
  'body-xsmall',
  'body-xsmall-bold',
  'button'
);

// fonts
@mixin font($size: $font-base) {
  // only apply everlane-typography styles for values found in $new-styles
  @if index($new-styles, $size) {
    @include everlane-typography('mobile-#{$size}');

    @include breakpoint(desktop) {
      @include everlane-typography('desktop-#{$size}');
    }
  } @else {
    @include set-type-dimensions($size, $size * 1.4, 0.02);
  }
}

@mixin font-size($size) {
  font-size: $size;
  line-height: 1.5em;
}

// reset
@mixin input-reset {
  -webkit-appearance: none;

  &::input-placeholder,
  &:input-placeholder,
  &::placeholder {
    font-family: everlane-font('sans');
  }

  &:focus {
    outline: none;
  }
}

// z-index
@mixin z-index($key) {
  z-index: index($z-indicies, $key);
}

// Use sparingly for elements that need to sit above every element
@mixin z-index-top {
  z-index: length($z-indicies) + 1;
}

@function static_image_url($source, $width: false, $dpr: 1.5, $quality: 60) {
  // TO-DO: Uncomment when we figure out image transformations here

  // @if str-slice($source, 0, 2) != 'i/' {
  //   $source: 'static/' + $source;
  // }
  // $options: '';
  // $options: $options + 'q_' + $quality;
  // $options: $options + ',dpr_' + $dpr;
  // @if $width {
  //   $options: $options + ',w_' + $width;
  // }

  $image-host: 'https://media.everlane.com/image/upload';

  @return $image-host + '/' + $source;
}

@mixin breakpoint($point) {
  @if $point == small-mobile {
    @media only screen and (max-width: 375px) {
      @content;
    }
  } @else if $point == mobile {
    @media only screen and (max-width: 768px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == desktop {
    // smaller laptops
    @media only screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == large-desktop {
    // laptops and desktops
    @media only screen and (min-width: 1200px) {
      @content;
    }
  } @else if $point == xlarge-desktop {
    // large monitors
    @media only screen and (min-width: 1600px) {
      @content;
    }
  }
}

@mixin visually-hidden {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

// Bazaarvoice Mixins
@mixin bv-body-small() {
  font-family: 'Maison Neue Book', Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.02rem !important;
  line-height: 16px !important;
}
