$use_maison: true;
/* stylelint-disable no-descending-specificity */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

$tile-width: 100px;

.style-picker,
.gift-card-picker {
  display: grid;
  grid-gap: $spacer-xxs;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;
  padding: 0;

  &__button {
    @include font($body-medium);

    background: $white;
    border: 1px solid $grey-200;
    text-transform: none;

    &--active {
      background-color: $grey-600 !important;
      border: 1px solid $grey-600;
      color: $white;
    }

    &:hover {
      border: 1px solid $grey-600;
    }

    &--disabled,
    &:disabled {
      background-color: $white !important;
      border: 1px solid $grey-200 !important;
      color: $grey-200 !important;
      cursor: not-allowed !important;
      overflow: hidden;
      position: relative;

      &::after {
        background: $grey-200;
        content: '';
        display: block;
        height: 1px;
        left: -0.75em;
        position: absolute;
        top: 24px;
        transform: rotate(-20.5deg);
        width: calc(100% + 1em);
      }

      &:hover,
      &:focus {
        border: 1px solid $grey-200 !important;
      }
    }

    &--long-label {
      padding: $spacer-xs;
    }
  }

  &--flex {
    align-items: center;
    display: flex !important;

    &::after {
      flex: 0;
    }

    & > button {
      background: $white;
      display: block;
      width: 100%;
    }
  }

  &__fit-container {
    margin: $spacer-s 0;
    padding: 0 $spacer-s;

    @include breakpoint(tablet) {
      padding: 0 $spacer-m;
    }

    @include breakpoint(desktop) {
      padding: 0;
    }
  }

  &__fit-label {
    @include font($body-small-bold);

    margin-bottom: $spacer-xxs;
    padding: 0;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      margin-bottom: $spacer-xs;
    }
  }
}

.product-picker {
  &__color-container {
    margin: $spacer-s 0 $spacer-s;
    padding: 0 $spacer-s;

    @include breakpoint(tablet) {
      margin-top: $spacer-m;
      padding: 0 $spacer-m;
    }

    @include breakpoint(desktop) {
      padding: 0;
    }
  }

  &__overlay {
    .product-picker__color-container {
      margin-top: 0;

      @include breakpoint(tablet) {
        padding: 0;
      }

      @include breakpoint(desktop) {
        margin-top: $spacer-m;
      }
    }

    .style-picker__fit-container {
      @include breakpoint(tablet) {
        padding: 0;
      }
    }
  }

  &__color {
    display: grid;
    grid-gap: $spacer-xxs;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: $spacer-xs;
    margin-left: 0;
    overflow: visible;
    padding: 0;

    @include breakpoint(desktop) {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  &__color-label {
    margin-bottom: $spacer-xxs;
    padding: 0;
    text-transform: uppercase;

    &:first-child {
      @include font($body-small-bold);

      margin-right: 6px;
    }
  }

  &__color-label--wrapper {
    @include font($body-small);

    margin-bottom: $spacer-xxs;
    padding: 0;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      margin-bottom: $spacer-xs;
    }

    &:not(:first-of-type) {
      padding: $spacer-s 0 0;
    }
  }

  &__inventory-message {
    color: $red-500;
    padding-left: 4px;
  }
}

.gift-card-picker {
  margin: 0 $spacer-s;

  @include breakpoint(tablet) {
    margin: 0;
  }

  &__link {
    @include font($body-medium);

    align-items: center;
    background-color: $white;
    border: 1px solid $grey-300;
    color: $black;
    display: flex;
    height: 47px;
    justify-content: center;
    outline: 0.5px solid transparent;
    text-decoration: none;

    &--active {
      background: $grey-600;
      border: 1px solid $grey-600;
      color: $white;
    }
  }

  &__section-title {
    @include font($body-small-bold);

    margin-bottom: $spacer-xxs;
    padding: 0 $spacer-s !important;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      margin-bottom: $spacer-xs;
      padding: 0 !important;
    }
  }
}

.giftcard-design {
  margin-top: $spacer-s;

  &__list {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 $spacer-s;

    @include breakpoint(tablet){
      margin: 0;
    }
  }

  &__item {
    background-color: $grey-100;
    border: 1px solid transparent;
    line-height: 0;

    &--selected {
      border-color: $grey-400;
    }
  }

  &__button {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__image {
    display: block;
    width: 100%;
  }

  .design-selector {
    margin: -20px 0 10px;
  }

  .section-title {
    @include font($body-small-bold);

    margin-bottom: $spacer-xxs;
    margin-left: $spacer-s;
    padding: 0;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      margin-bottom: $spacer-xs;
      margin-left: 0;
    }
  }
}

.section-description {
  @include font($body-small);

  color: $grey-400;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.section-title {
  @include font($body-medium-bold);

  margin-bottom: 5px;
  padding-left: 12px;
  position: relative;
  white-space: nowrap;

  @include breakpoint(tablet) {
    padding-left: 0;
  }
}
