$spacer-xxs: 4px;
$spacer-xs: 8px;
$spacer-s: 16px;
$spacer-sm: 24px;
$spacer-m: 32px;
$spacer-ml: 48px;
$spacer-l: 64px;
$spacer-xl: 128px;
$spacer-xxl: 256px;
$desktop-header-height: 154px;
$desktop-viewport-minus-header: calc(100vh - #{$desktop-header-height});
