$use_maison: true;
/* stylelint-disable no-descending-specificity */

@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

$product-size-width: 48px;

.product-sizes__container {
  margin: $spacer-s 0 $spacer-m;
  min-height: unset;
  padding: 0 $spacer-s;

  @include breakpoint(tablet) {
    padding: 0 $spacer-m;
  }

  @include breakpoint(desktop) {
    padding: 0;
  }

  @include breakpoint(desktop) {
    height: auto;
    padding-bottom: 0;
  }

  section + section {
    margin-top: $spacer-s;
  }

  &--skeleton > {
    & span {
      display: flex;
      gap: $spacer-xxs;
      margin-top: $spacer-xs;
    }
  }

  &--overlay {
    @include breakpoint(tablet) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--gift-card {
    margin-bottom: $spacer-s;

    .section-title {
      @include font($body-small-bold);

      margin-bottom: $spacer-xxs;
      padding: 0;
      text-transform: uppercase;

      @include breakpoint(tablet) {
        margin-bottom: $spacer-xs;
      }
    }
  }
}

.product-sizes {
  display: grid;
  grid-gap: $spacer-xxs;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 1px;
  padding-left: 0;

  @include breakpoint(desktop) {
    margin: 0;

    &--mens-denim {
      margin-bottom: $spacer-xs;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    flex: 0 0 $spacer-xxs;
  }

  &__skeleton {
    margin-top: $spacer-s;

    .product-sizes__label {
      @include font($body-small-bold);

      margin-bottom: $spacer-xxs;
      padding: 0;
      text-transform: uppercase;
    }
  }

  &__overlay {
    margin-top: calc($spacer-s - $spacer-xxs);
    min-height: auto;
    padding: 0;

    @include breakpoint(tablet) {
      margin-bottom: calc($spacer-s - $spacer-xxs);
    }
  }

  &--more-variants {
    &--overlay {
      padding-bottom: 0;
    }
  }

  &--giftcard {
    display: flex;
    margin: 0;

    &::after {
      display: none;
    }
  }

  &__inventory-message {
    @include font($body-small);

    margin-left: auto;
    margin-right: 0;
    padding-right: 0;

    &__date {
      @include font($body-small-bold);
    }
  }

  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer-xxs;
    padding: 0;
    text-transform: uppercase;

    &::after {
      flex: 0;
    }

    &--error {
      color: $red-600;
    }

    &__title {
      @include font($body-small-bold);
    }
  }

  &__selected-size {
    display: inline-block;
    margin-top: 12px;
  }

  &__select-a-size {
    @include font($body-small);

    color: $grey-400;
    display: inline-block;
    font-family: everlane-font('sans--italic');
    margin-top: 12px;

    @include breakpoint(tablet) {
      padding-left: 40px;
    }

    @include breakpoint(desktop) {
      padding-left: 0;
    }
  }

  &__size-guide--desktop {
    @include font($body-small);

    display: inline-block;
    margin-bottom: 18px;

    button {
      color: $grey-400;
    }
  }

  &__inline-breakpoint {
    display: inline-block;
  }

  &__size-label {
    align-items: center;
    display: flex;
    justify-content: space-between;

    span {
      @include font($body-small);

      margin: 0;
    }
  }
}

.inventory-message {
  margin-right: 0;
  padding-right: 0;

  &--waitlist {
    @include font($body-small);

    color: $grey-400;
  }

  &--warning {
    @include font($body-small);

    color: #ad5700;
  }

  &--low-stock {
    @include font($body-small);

    color: #ad5700;
  }

  &--pre-order {
    @include font($body-small);

    color: $grey-400;
  }

  &__date {
    @include font($body-small-bold);
  }
}

.product-size {
  @include font($body-medium);

  background: $white;
  border: 1px solid $grey-200;
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0;
  min-width: auto;
  padding: 12px $spacer-xs;
  text-transform: none;

  &:hover,
  &:focus {
    background: $white !important;
    border: 1px solid $grey-500 !important;
    color: $black;
  }

  &--preview {
    border: 1px solid $grey-300;
  }

  &--active {
    background: $grey-600 !important;
    border-color: $grey-600 !important;
    color: $white;

    &:hover,
    &:focus {
      background: $grey-600 !important;
      border-color: $grey-600 !important;
      color: $white;
    }
  }

  &--sold-out {
    background-color: $white !important;
    border: 1px solid #e0dfdf;
    color: #e0dfdf !important;
    overflow: hidden;
    position: relative;

    &:hover,
    &:focus {
      border: 1px solid #e0dfdf !important;
    }

    &::after {
      background-color: $grey-300;
      content: '';
      height: 1px;
      left: -6px;
      position: absolute;
      top: 50%;
      transform: rotate(-26deg);
      width: calc(100% + 11px);
    }

    &--active {
      background: $white !important;
      border-color: $grey-600 !important;

      &:hover,
      &:focus {
        background: $white !important;
        border-color: $grey-600 !important;
      }
    }
  }

  &--waitlistable {
    &--active {
      background-color: $grey-400;
      color: $white;
    }
  }

  &--sneaker {
    min-width: 57px;
  }
}

// @media (hover: hover) and (pointer: fine) {
//   .product-size:hover {
//     border: 1.5px solid $grey-500;
//   }
// }

.section-description {
  @include font($body-small);

  color: $grey-400;
  font-style: italic;
  line-height: 1.4em;
  margin-bottom: 20px;
  margin-left: $spacer-s;
}

.section-title {
  @include font($body-medium-bold);

  margin-bottom: $spacer-xxs;
  padding-left: 12px;
  position: relative;
  white-space: nowrap;

  @include breakpoint(tablet) {
    padding-left: 0;
  }
}

.giftcard-price {
  width: 50%;

  &--active {
    background: $grey-600;
    border: 1px solid $grey-600;
    color: $white;
  }

  &:hover,
  &:focus {
    background: $grey-600 !important;
    border: 1px solid $grey-600 !important;
    color: $white !important;
  }
}
