$use_maison: true;
@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/spacers';

.product-badge {
  @include z-index(carousel-badge);

  background-color: $white;
  color: $black;
  margin-right: 20px;
  position: absolute;
}

.product-badge--large {
  @include font($body-small);

  bottom: $spacer-xs;
  left: $spacer-xs;
  padding: 4px 6px;

  @include breakpoint(tablet) {
    bottom: unset;
    top: $spacer-xs;
  }
}

.product-badge--small {
  @include font($body-xsmall);

  bottom: $spacer-xs;
  left: $spacer-xs;
  padding: 3px 5px 2px;

  @include breakpoint(tablet) {
    bottom: unset;
    top: $spacer-xs;
  }
}

.product-badge--uniform {
  @include font($body-small);

  background: $black;
  color: $white;
  letter-spacing: 0.08em !important;
}

.product-badge--discount {
  color: $red-500;
}

.product-badge__holiday--best-seller {
  color: #af5845;
}

.product-badge__holiday--one-size-fits-all {
  color: #8a7151;
}

.product-badge--experiment {
  @include font($body-xsmall);

  bottom: $spacer-xxs;
  color: $black;
  left: $spacer-xxs;
  padding: 0 5px;
  text-transform: uppercase;

  @include breakpoint(tablet) {
    bottom: unset;
    top: $spacer-xxs;
  }

  &.product-badge--uniform {
    color: $white;
  }
}
