$use_maison: true;
@import 'styles/colors';

.image-hover {
  transition: opacity 150ms linear;

  &:hover {
    opacity: 0;
  }

  &__container {
    position: relative;
  }

  &__inner-container {
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.responsive-image {
  background-color: $grey-100;
  display: block;
  height: auto;
  position: relative;
  width: 100%;
}
